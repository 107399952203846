import React from 'react'
import { Link } from 'gatsby'
import 'components/navibar/style.scss'
import Logo from '../../../content/images/logo.png'
import MobileDropdown from 'components/navibar/navibar-mobile'
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { FaClipboardList } from "@react-icons/all-files/fa/FaClipboardList";

interface Props {
  title: string
  location: Location
}

const Navibar: React.FC<Props> = ({ location, title }: Props) => {
  return (
    <div>
      <div id="header-cta">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="header-list inline-list">
                <li>
                  <FaMapMarkerAlt className="svg-icon-middle text-primary" /><span className="text-primary">  6621 W Broadway STE 600, McCordsville IN, 46055</span>
                </li>
                <li>
                  <FaClipboardList className="svg-icon-middle text-primary" /><span className="text-primary">  <a href="https://flexbook.me/tdagc/website" target="_blank" className="text-primary">Book Online</a></span>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 text-right">
              <ul className="header-list inline-list">
                <li>
                  <FaPhoneAlt className="svg-icon-middle text-primary" /><span className="text-primary">  <a href="tel:3176439434" className="text-primary">317-643-9434</a></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand navbar-light flex-column flex-md-row bg-gray" id="main-navigation">
        <div className="container">
          <div className="hidden-md-up">
            <MobileDropdown />
          </div>
          <div className="col-lg-6">
            <Link className="text-center" to="/">
              <img src={Logo} height="91" width="350"  id="logo" alt="the dentist at gateway crossing"/>
            </Link>

          </div>
          <div className="col-lg-6">
            <div className="navbar-nav-scroll hidden-md-down">
              <ul className="navbar-nav bd-navbar-nav justify-content-center">
                <li
                  className={
                    location.pathname === '/'
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li
                  className={
                    location.pathname.indexOf("/about/") == 0
                      ? 'nav-item active nav-dropdown'
                      : 'nav-item nav-dropdown'
                  }
                >
                  <Link to="/about/" className="nav-link">
                    About
                  </Link>
                  <ul className="nav-submenu bg-main">
                    <li
                      className={
                        location.pathname === '/about/office/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/about/office/" className="nav-link">
                        Office
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/about/our-team/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/about/our-team/" className="nav-link">
                        Our Team
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/about/dr-vogt/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/about/dr-vogt/" className="nav-link">
                        Dr. Vogt
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/about/dr-summers/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/about/dr-summers/" className="nav-link">
                        Dr. Summers
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    location.pathname.indexOf("/services/") == 0
                      ? 'nav-item active nav-dropdown'
                      : 'nav-item nav-dropdown'
                  }
                >
                  <Link to="/services/" className="nav-link">
                    Services
                  </Link>
                  <ul className="nav-submenu bg-main">
                    <li
                      className={
                        location.pathname === '/services/preventative-dentistry/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/preventative-dentistry/" className="nav-link">
                        Preventative Dentistry
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/restorative-dentistry/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/restorative-dentistry/" className="nav-link">
                        Restorative Dentistry
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/cosmetic-dentistry/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/cosmetic-dentistry/" className="nav-link">
                        Cosmetic Dentistry
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/implant-dentistry/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/implant-dentistry/" className="nav-link">
                        Implant Dentistry
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/oral-surgery/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/oral-surgery/" className="nav-link">
                        Oral Surgery
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/periodontal-treatment/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/periodontal-treatment/" className="nav-link">
                        Periodontal Treatment
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/sedation-dentistry/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/sedation-dentistry/" className="nav-link">
                        Sedation Dentistry
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/root-canal-therapy/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/root-canal-therapy/" className="nav-link">
                        Root Canal Therapy
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/family-dentistry/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/family-dentistry/" className="nav-link">
                        Family Dentistry
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/services/pediatric-dentistry/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/services/pediatric-dentistry/" className="nav-link">
                        Pediatric Dentistry
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    location.pathname === '/testimonials/'
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link to="/testimonials/" className="nav-link">
                    Testimonials
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === '/blog/'
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link to="/blog/" className="nav-link">
                    Blog
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === '/contact/'
                      ? 'nav-item active nav-dropdown'
                      : 'nav-item nav-dropdown'
                  }
                >
                  <Link to="/contact/" className="nav-link">
                    Contact
                  </Link>
                  <ul className="nav-submenu bg-main">
                    <li
                      className={
                        location.pathname === '/contact/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/contact/" className="nav-link">
                        Contact Us
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                    >
                      <a href="https://flexbook.me/tdagc/website" className="nav-link" target="_blank" rel="noopener">
                        Schedule Online
                      </a>
                    </li>
                    <li
                      className="nav-item"
                    >
                      <a href="https://orders.transafe.com/hosted_paypage/23036587062493102" target="_blank" className="nav-link">
                        Make A Payment
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
        </div>
      </nav>
    </div>
  )
}

export default Navibar
