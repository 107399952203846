import React, { Component } from 'react';
import { Link } from 'gatsby'
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaBars } from "@react-icons/all-files/fa/FaBars";

class MobileNavigation extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      hideMenu: true,
    };

    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  hideMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: false });
  }

  render() {
    return (
      <div>
        {
          this.state.showMenu
            ? (
              <button className="mobile-nav text-center" onClick={this.hideMenu}>
                <FaTimes size="21px" className="svg-icon-middle text-white"/>
              </button>
            )
            : (
              <button className="mobile-nav text-center" onClick={this.showMenu}>
                <FaBars size="21px" className="svg-icon-middle text-white"/>
              </button>
            )
        }
        {
          this.state.showMenu
            ? (
              <div
                className="menu mobile-menu"
              >
                <div className="hidden-md-up">
                  <ul className="navbar-nav bd-navbar-nav justify-content-end">
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li>
                      <div className="btn-group">
                        <Link to="/about/">
                          <button type="button" className="btn btn-menu-mobile text-white">ABOUT</button>
                        </Link>
                        <button type="button" className="btn btn-menu-mobile text-white dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul className="nav-submenu bg-main dropdown-menu">
                          <li
                            className={
                              location.pathname === '/about/office'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/about/office" className="nav-link">
                              Office
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/about/our-team'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/about/our-team/" className="nav-link">
                              Our Team
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/about/dr-vogt'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/about/dr-vogt" className="nav-link">
                              Dr. Vogt
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/about/dr-summers'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/about/dr-summers" className="nav-link">
                              Dr. Summers
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="btn-group">
                        <Link to="/services">
                          <button type="button" className="btn btn-menu-mobile text-white">SERVICES</button>
                        </Link>
                        <button type="button" className="btn btn-menu-mobile text-white dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul className="nav-submenu bg-main dropdown-menu">
                          <li
                            className={
                              location.pathname === '/services/preventative-dentistry'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/preventative-dentistry" className="nav-link">
                              Preventative Dentistry
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/restorative-dentistry'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/restorative-dentistry" className="nav-link">
                              Restorative Dentistry
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/cosmetic-dentistry'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/cosmetic-dentistry" className="nav-link">
                              Cosmetic Dentistry
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/implant-dentistry'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/implant-dentistry" className="nav-link">
                              Implant Dentistry
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/oral-surgery'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/oral-surgery" className="nav-link">
                              Oral Surgery
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/periodontal-treatment'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/periodontal-treatment" className="nav-link">
                              Periodontal Treatment
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/sedation-dentistry'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/sedation-dentistry" className="nav-link">
                              Sedation Dentistry
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/root-canal-therapy'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/root-canal-therapy" className="nav-link">
                              Root Canal Therapy
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/family-dentistry'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/family-dentistry" className="nav-link">
                              Family Dentistry
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/services/pediatric-dentistry'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/services/pediatric-dentistry" className="nav-link">
                              Pediatric Dentistry
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li
                      className={
                        location.pathname === '/testimonials'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/testimonials/" className="nav-link">
                        Testimonials
                      </Link>
                    </li>
                    <li>
                      <div className="btn-group">
                        <Link to="/contact/">
                          <button type="button" className="btn btn-menu-mobile text-white">CONTACT</button>
                        </Link>
                        <button type="button" className="btn btn-menu-mobile text-white dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul className="nav-submenu bg-main dropdown-menu">
                          <li
                            className={
                              location.pathname === '/contact'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <Link to="/contact" className="nav-link">
                              Contact Us
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === '/contact'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <a href="https://flexbook.me/tdagc/website" className="nav-link" target="_blank">
                              Schedule Online
                            </a>
                          </li>
                          <li
                            className={
                              location.pathname === '/contact'
                                ? 'nav-item dropdown-item active'
                                : 'nav-item dropdown-item'
                            }
                          >
                            <a href="https://orders.transafe.com/hosted_paypage/23036587062493102" target="_blank" className="nav-link">
                              Make A Payment
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )
            : (
              null
            )
        }
      </div>
    );
  }
}

export default MobileNavigation
