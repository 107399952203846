import React, { Component } from 'react'
import { Link } from 'gatsby'
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";

interface Props {
  path: string
  label: string
  primary: boolean
}

class BtnSolid extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
      typeof window !== "undefined" && window.gtag("conversion", "click", { send_to: ["AW-708502388/dOiKCOrXoqsBEPTG69EC"]});
      return gtag_report_conversion('tel:3176439434');
      console.log("gtag_report_conversion");
  }    

  render() {
      return (
        <a href={this.props.url} onClick={this.handleClick} className="text-left"><button className={this.props.class}><FaPhoneAlt size="20px" className="svg-icon-middle text-white" /> {this.props.label} </button></a>
      )
  }
}

export default BtnSolid;