import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

const handleKeyDown = e => {
 if (e.key === " " &&  e.target.selectionStart === 0 ) {
   e.preventDefault();
 }
};

const ContactForm = ({ ...props }) => (

  <form action="https://usebasin.com/f/356a79c24bb9" method="POST" id="contact-form">
    <div className="row">
      <div className="col-lg-12 p-t-15">
        <label for="first-name">Name:</label>
        <input type="text" id="first-name" name="name" placeholder="" onKeyDown={handleKeyDown} required/>
      </div>
      <div className="col-lg-6">
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" placeholder="" required />
      </div>
      <div className="col-lg-6">
        <label for="first-name">Phone:</label>
        <input type="tel" id="phone" name="phone" placeholder="" required />
      </div>

      {/*<div className="col-lg-6">
        <label for="first-name">First Name:</label>
        <input type="text" id="first-name" name="firstname" placeholder="" required/>
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" placeholder="" required />
      </div>
      <div className="col-lg-6">
        <label for="first-name">Last Name:</label>
        <input type="text" id="last-name" name="lastname" placeholder="" required/>
        <label for="first-name">Phone:</label>
        <input type="tel" id="phone" name="phone" placeholder="" required />
      </div> */}
      <div className="col-lg-6">
        <label>What can we help you with?</label><br />
        <input type="checkbox" name="cleaning/checkup" id="cleaning/checkup" value="Cleaning/checkup" /> <label for="cleaning/checkup" className="small text-white">Cleaning/checkup</label><br />
        <input type="checkbox" name="tooth-sensitivity" id="tooth-sensitivity" value="Tooth sensitivity" /> <label for="tooth-sensitivity" className="small text-white">Tooth sensitivity</label><br />
        <input type="checkbox" name="missing-teeth" id="missing-teeth" value="Missing teeth" /> <label for="missing-teeth" className="small text-white">Missing teeth</label><br />
        <input type="checkbox" name="cracked-tooth" id="cracked-tooth" value="Cracked tooth" /> <label for="cracked-tooth" className="small text-white">Cracked tooth</label>
      </div>
      <div className="col-lg-6">
        <input type="checkbox" name="gumpain/bleeding" id="gumpain/bleeding" value="Gum pain/bleeding" /> <label for="gumpain/bleeding" className="small text-white">Gum pain/bleeding</label><br />
        <input type="checkbox" name="general-pain-or-soreness" id="general-pain-or-soreness" value="General pain or soreness" /> <label for="general-pain-or-soreness" className="small text-white">General pain or soreness</label><br />
        <input type="checkbox" name="cosmetic-improvement" id="cosmetic-improvement" value="Cosmetic improvement" /> <label for="cosmetic-improvement" className="small text-white">Cosmetic improvement</label><br />
        <input type="checkbox" name="dental-implants" id="dental-implants" value="Dental implants" /> <label for="dental-implants" className="small text-white">Dental implants</label><br />
        <input type="checkbox" name="invisalign" id="invisalign" value="Invisalign" /> <label for="invisalign" className="small text-white">Invisalign</label><br />
      </div>
      <div className="col-lg-12">
        <label for="first-name">Include additional information and any questions you may have:</label>
        <textarea id="subject" name="additional-information" rows="3" placeholder="" required></textarea>
        <input type="submit" value="Submit" />
      </div>
    </div>
 </form>
)
export default ContactForm
