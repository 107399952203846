import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'
import ContactForm from 'components/contact-form'
import Map from 'components/map-embed'
import BtnSolid from 'components/button/button-solid'
import TelLink from 'components/tel-link'
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { FaEnvelopeOpen } from "@react-icons/all-files/fa/FaEnvelopeOpen";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";


const Footer = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        footer_bg: file(name: { eq: "footer-bg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        footercta: file(name: { eq: "section_4_bg" }) {
          childImageSharp {
            fluid(maxWidth: 1300, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        office_map_lg: file(name: { eq: "the-dentists-at-gc-map-lg" }) {
          childImageSharp {
            fluid(maxWidth: 1050, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const footer_bg = data.footer_bg.childImageSharp.fluid
  const footer = data.footercta.childImageSharp.fluid
  const office_map_lg = data.office_map_lg.childImageSharp.fluid

  return (
    <div>
      <BackgroundImage
        Tag="section"
        className="footer_cta"
        fluid={footer}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateaway Crossing Book An Appointment"
      >
        <div className ="col-lg-12" id ="color-overlay-dark"></div>
        <section id="office-section-4" className="text-center page-section">
          <Container>
            <div className="col-lg-12 ">
              <a href="https://flexbook.me/tdagc/website"><button className="btn-rounded">Book An Appointment </button></a>
            </div>
          </Container>
        </section>
      </BackgroundImage>
      <section className="footer footer-form">
        <Container>
          <ContactForm />
        </Container>
      </section>
      <BackgroundImage
        Tag="section"
        className="footer"
        fluid={footer_bg}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateaway Crossing"
      >
        <div id="footer" className="page-section">
          <ContainerFluid>
            <div className="col-lg-12">
              <h2 className="text-center text-white">Stay Connected!</h2>
            </div>
            <div className="text-center col-lg-12 footer-social">
              <div className="text-center footer-icon-container">
                  <div className="footer-icons facebook">
                    <a href="https://www.facebook.com/thedentistsatgc/" aria-label="Follow us on Facebook">
                      <FaFacebookF className="text-white svg-icon-middle" size="25" />
                    </a>
                  </div>
                  <div className="footer-icons instagram">
                    <a href="http://instagram.com/thedentistsatgc" aria-label="Follow us on Instagram">
                      <FaInstagram className="text-white svg-icon-middle" size="25" />
                    </a>
                  </div>
                  <div className="footer-icons twitter">
                    <a href="https://twitter.com/thedentistsatgc" aria-label="Follow us on Twitter">
                      <FaTwitter className="text-white svg-icon-middle" size="25" />
                    </a>
                  </div>
                </div>
            </div>
            <div className="col-lg-12">
              <hr className="text-white"/>
              <h3 className="text-center text-white">Booking appointments with our gentle dentist is easy at our McCordsville dental office. Click the button to book online!</h3>
            </div>
            <div className="text-center col-lg-6">
              <Link to="/contact/"><button className="btn-rounded btn-solid bg-main">Book Appointment</button></Link>
            </div>
            <div className="text-center col-lg-6">
              <BtnSolid url="tel:3176439434" label="(317) 643-9434" class="btn-rounded btn-solid bg-main" />
            </div>
            <div className="col-lg-12 m-t-35 m-b-20">
              <Map mapSrcURL="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d391844.8284369874!2d-85.930534!3d39.89019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6f4e92eaa919dbc5!2sThe%20Dentists%20at%20Gateway%20Crossing!5e0!3m2!1sen!2sus!4v1613657636924!5m2!1sen!2sus" mapThumbSrcURL={office_map_lg} alt="The Dentists at Gateway Crossing Google Map Complete Address" />
            </div>
            <div className="col-lg-6">
              <h3 className="text-center text-white bottom-20">ADDRESS & CONTACT DETAILS</h3>
              <p className="text-center text-white contact-info">
               <FaMapMarkerAlt className="text-primary svg-icon-middle" /> 6621 W Broadway<br /> STE 600, McCordsville, <br />
                IN 46055</p>
              <p className="text-center text-white contact-info">
                <TelLink url="tel:3176439434" class="text-white text-center" label="317-643-9434" />
              </p>
              <p className="text-center text-white contact-info">
                 <a className="text-center text-white" href="mailto:office@thedentistsatgc.com"><FaEnvelopeOpen className="text-primary svg-icon-middle" /> Email Us</a>
              </p>
            </div>
            <div className="col-lg-6">
              <h3 className="text-center text-white bottom-20">BUSINESS HOURS</h3>
              <p className="text-center text-white contact-info">
               Monday 9AM - 6PM
              </p>
              <p className="text-center text-white contact-info">
                Tuesday 8AM - 5PM
              </p>
              <p className="text-center text-white contact-info">
                Wednesday 8AM - 5PM
              </p>
              <p className="text-center text-white contact-info">
                Thursday 8AM - 5PM
              </p>
              <p className="text-center text-white contact-info">
                Friday Closed
              </p>
          </div>
            <div className="col-lg-12">
              <div className="footer-link col-md-12">
                <ul>
                  <li><Link to="/terms-of-service/" className="text-white">Terms of Service</Link></li>
                  <li><Link to="/privacy-policy/" className="text-white">Privacy Policy</Link></li>
                  <li><Link to="/hipaa/" className="text-white">HIPAA</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <p className="text-center text-white footer-copyright copyright">©  {new Date().getFullYear()}. All Rights Reserved. <a href="tel:3176439434">317-643-9434</a></p>
              <p className="text-center footer-bird-digital">Web Development by <a href="https://adrienbird.com/" target="_blank">Bird Digital</a></p>
            </div>
          </ContainerFluid>
        </div>
      </BackgroundImage>
    </div>

  )
}

export default Footer
